import React from 'react';
import './DropBox.scss';
import { ReactSVG } from 'react-svg';
import down_icon from '../../Assets/Icons/down_icon.png';
import up_icon from '../../Assets/Icons/up_icon.png';

export default class DropBox extends React.Component {
    state = {
        className: this.props.className || '',
        value: this.props.value || '',
        category: this.props.category || [],
        isShowList: false
    }

    onSelect = (item) => {
        this.setState({
            value: item.name,
            isShowList: !this.state.isShowList
        });
        this.props.onChange(item);
    }

    render() {
        const { isShowList, className, value, icon, category } = this.state;
        return (
            <div className={`dropbox-component v-c round-object ${className}`}>
                <div className="dropbox-area v-r">
                    <div className="drop-header" onClick={()=>this.setState({isShowList: !isShowList})}>
                        <div className="">{value}</div>
                        <img src={isShowList? up_icon : down_icon} alt="app-stores" />
                    </div>
                    {isShowList && category.map((item, index)=> (
                        <div className="category-item" onClick={()=> this.onSelect(item)}>{item.name}</div>
                    ))}
                </div>
            </div>
        )
    };
};
