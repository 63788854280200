import { Api } from './Api';


export function* zipcodeavailable(zipcode){
    const jsonData = yield Api.get(`/zipcodeavailable?zipcode=${zipcode}`);
    return jsonData;
}

export function* requestpickup(data){
    const jsonData = yield Api.post("/requestpickup", data);
    return jsonData;
}

export function* requestNewZip(data){
    const jsonData = yield Api.post("/requestpickup", data);
    return jsonData;
}