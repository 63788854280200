import { GET_PRICING, GET_PRICING_FAIL, GET_PRICING_SUCCESS } from '../Type';

const INITIAL = {
    pricing_list: [],
    get_pricing_success: false,
    get_pricing_loading: false,
}

export default (state = INITIAL, action) => {
    switch (action.type) {
        case GET_PRICING: {
            return {
                get_pricing_success: false,
                get_pricing_loading: true,
            }
        }
        case GET_PRICING_FAIL: {
            return {
                pricing_list: [],
                get_pricing_success: false,
                get_pricing_loading: false,
            }
        }
        case GET_PRICING_SUCCESS: {
            let pricing_list = action.data || [];
            return {
                pricing_list: pricing_list,
                get_pricing_success: true,
                get_pricing_loading: false,
            }
        }

        default:
            return state;
    }
}