import { 
  ZIP_CODE_AVAILBLE, ZIP_CODE_AVAILBLE_FAIL, ZIP_CODE_AVAILBLE_SUCCESS,
  REQUEST_PICKUP, REQUEST_PICKUP_FAIL, REQUEST_PICKUP_SUCCESS,
  REQUEST_NEW_ZIP, REQUEST_NEW_ZIP_FAIL, REQUEST_NEW_ZIP_SUCCESS,
 } from '../Type';

const INITIAL = {
    zipcodeavailable_loading: false,
    zipcodeavailable_success: false,
    zipcodeavailable_msg: '',

    pickup_loading: false,
    pickup_success: false,
    pickup_msg: '',
    
    request_newZip_loading: false,
    request_newZip_success: false,
    request_newZip_msg: '',
}

export default (state = INITIAL, action) => {
  switch (action.type) {
    case ZIP_CODE_AVAILBLE: {
      return {
        ...state,
        zipcodeavailable_loading: true,
        zipcodeavailable_success: false,
        zipcodeavailable_msg: '',
      }
    }
    case ZIP_CODE_AVAILBLE_SUCCESS: {
      return {
        ...state,
        zipcodeavailable_loading: false,
        zipcodeavailable_success: true,
        zipcodeavailable_msg: action.data.msg,
      }
    }
    case ZIP_CODE_AVAILBLE_FAIL: {
      return {
        ...state,
        zipcodeavailable_loading: false,
        zipcodeavailable_success: false,
        zipcodeavailable_msg: action.data.msg,
      }
    }

    case REQUEST_PICKUP: {
      return {
        ...state,
        pickup_loading: true,
        pickup_success: false,
        pickup_msg: '',
      }
    }
    case REQUEST_PICKUP_SUCCESS: {
      return {
        ...state,
        pickup_loading: false,
        pickup_success: true,
        pickup_msg: action.data.msg,
      }
    }
    case REQUEST_PICKUP_FAIL: {
      return {
        ...state,
        pickup_loading: false,
        pickup_success: false,
        pickup_msg: action.data.msg,
      }
    }

    case REQUEST_NEW_ZIP: {
      return {
        ...state,
        request_newZip_loading: true,
        request_newZip_success: false,
        request_newZip_msg: '',
      }
    }
    case REQUEST_NEW_ZIP_SUCCESS: {
      return {
        ...state,
        request_newZip_loading: false,
        request_newZip_success: true,
        request_newZip_msg: action.data.msg,
      }
    }
    case REQUEST_NEW_ZIP_FAIL: {
      return {
        ...state,
        request_newZip_loading: false,
        request_newZip_success: false,
        request_newZip_msg: action.data.msg,
      }
    }

    default:
      return state;
  }
}