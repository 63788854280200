import {
    GET_PRICING, GET_PRICING_SUCCESS, GET_PRICING_FAIL
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
    getPricing as getPricingApi
} from '../../Api';

function* getPricing(payload){
    try {
        const result = yield getPricingApi(payload.pricing)
        if (result.data && result.data.length > 0) {
            yield put({ type: GET_PRICING_SUCCESS, data: result.data })
        } else {
            yield put({ type: GET_PRICING_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_PRICING_FAIL, data: [] })
    }
}

export function* watchGetPricing(){
    yield takeLatest(GET_PRICING, getPricing)
}